<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push('/login');
    }
  },
  mounted() {
    //页面监听页面刷新和关闭
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
  },
  destroyed() {
    //页面销毁前关闭监听
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    beforeunloadFn(e) {
      this._beforeUnload_time = new Date().getTime();
    },
    async unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {//浏览器关闭
        //自己的操作行为
        console.log('关闭浏览器')
        localStorage.clear()
      } else {
        console.log('刷新浏览器')
        this.clearTargetId()
      }
    },
    //清空targetId
    clearTargetId() {
      this.$store.commit('setTargetId', '')
    }
  }

};
</script>
<style>
@import url("~assets/css/reset.css");
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #28a8cf;
  border-color: #28a8cf;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #28a8cf;
}
.el-checkbox.is-bordered.is-checked {
  border-color: #28a8cf;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #28a8cf;
}
</style>

