import Vue from 'vue'
import Vuex from 'vuex'

// 用户
import user from './user/user'
import updateNum from './updateNum/updateNum'  //统一更改左侧tab图标数量
import rongyun from './rongyun/rongyun'  //设置融云的实例化 方便各个组件取出使用
Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    user,
    updateNum,
    rongyun,
  }
})