// 首页的接口
import axios from '../axios'

const index = {
    // 新闻列表    
    articleList() {
        return axios.get(`topics/topics`);
    },
    // 新闻详情,演示    
    articleDetail(id, params) {
        return axios.get(`topics/topic/${id}`, {
            params: params
        });
    },
    // 登录    
    login(params) {
        return axios.post(`/jeecg-member/api/z/login`, params);
    }
    // 其他接口…………
}
export default index