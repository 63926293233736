import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: '',
  redirect: '/index'
}, {
  path: '/login',
  name: 'login',
  component: () => import( /* webpackChunkName: "about" */ '@/views/login/index.vue')

}, {
  path: '/index',
  name: 'index',
  component: () => import('@/views/index/index.vue'),
  redirect: '/index/privateLetter',
  children: [{
    // 私信
    path: 'privateLetter',
    name: 'privateLetter',
    component: () => import('@/views/index/privateLetter')
  },
  {
    // 通讯录
    path: 'addressbook',
    name: 'addressbook',
    component: () => import('@/views/index/addressbook/addressbook.vue')
  },
  {
    // 秘书台
    path: 'secretaryStation',
    name: 'secretaryStation',
    component: () => import('@/views/index/secretaryStation')
  },
  {
    // 邮箱
    path: 'mailbox',
    name: 'mailbox',
    component: () => import('@/views/index/mailbox/index'),
    redirect: 'mailbox/InboxDetail',
    children: [
      {
        // 收件
        path: 'InboxDetail',
        name: 'InboxDetail',
        component: () => import('@/views/index/mailbox/InboxDetail'),
      },
      {
        // 收件详情
        path: 'InboxDetailInfo',
        name: 'InboxDetailInfo',
        component: () => import('@/views/index/mailbox/components/InboxDetailInfo'),
        hidden: true
      },
      {
        // 收件详情-回复
        path: 'InboxDetailReply',
        name: 'InboxDetailReply',
        component: () => import('@/views/index/mailbox/components/InboxDetailReply'),
        hidden: true
      }, {
        // 发邮件
        path: 'sendEmails',
        name: 'sendEmails',
        component: () => import('@/views/index/mailbox/sendEmails'),
      },
      {
        // 发邮件-选择收件人
        path: 'recipientsDetail',
        name: 'recipientsDetail',
        component: () => import('@/views/index/mailbox/components/recipientsDetail'),
        hidden: true
      },
      {
        // 已发邮件
        path: 'sentItems',
        name: 'sentItems',
        component: () => import('@/views/index/mailbox/sentItems'),
      },
      {
        // 已发邮件-转发
        path: 'sentItemsTranspond',
        name: 'sentItemsTranspond',
        component: () => import('@/views/index/mailbox/components/sentItemsTranspond'),
        hidden: true
      },
      {
        // 邮箱-草稿箱
        path: 'draftBox',
        name: 'draftBox',
        component: () => import('@/views/index/mailbox/draftBox'),
      },
      {
        // 邮箱-垃圾箱
        path: 'dustbinBox',
        name: 'dustbinBox',
        component: () => import('@/views/index/mailbox/dustbinBox'),
      },{
        // 邮箱-邮件详情
        path: 'emailDetail',
        name: 'emailDetail',
        component: () => import('@/views/index/mailbox/emailDetail'),
      },
    ]
  },
  {
    // 发蜘客
    path: 'sendSpider',
    name: 'sendSpider',
    component: () => import('@/views/index/sendSpider')
  },
  {
    // 文件
    path: 'file',
    name: 'file',
    component: () => import('@/views/index/file')
  },
  {
    // 上传音乐
    path: 'uploadMusic',
    name: 'uploadMusic',
    component: () => import('@/views/index/uploadMusic')
  },
  {
    // 上传表情
    path: 'uploadExpression',
    name: 'uploadExpression',
    component: () => import('@/views/index/uploadExpression')
  }
  ,
  {
    // 上传视频
    path: 'uploadVideo',
    name: 'uploadVideo',
    component: () => import('@/views/index/uploadVideo')
  }
  ]
}

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if (store.user.state.token) { // 通过vuex state获取当前的token是否存在
      next();
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next();
  }
})
export default router