export default {
    state: {
        RongIMClient: null,//融云实例化
        sendMsgArr: [], //接收的消息数组 用来做私信的列表
        targetId: '',//设置targetId
        messageList:[],//消息列表
        msgUpdateStatus:0, //改变就调用消息列表
        updateList:false, //更新消息列表
    },
    //更新token 因为有时候token 通过 localStorage是不同步的
    mutations: {
        //设置实例化融云
        setRongIMClient(state, newVal) {
            state.RongIMClient = newVal
            // console.log("设置融云实例成功", state.RongIMClient)
        },
        //更新消息列表
        changeMsgList(state, newVal) {
            state.updateList = !state.updateList
            // console.log("更新消息列表成功", state.messageList)
        },
        //设置接收发送的消息
        setSendMsgArr(state, newVal) {
            state.sendMsgArr = newVal
            // console.log("设置接收发送的消息成功", state.sendMsgArr)
            // console.log("获取到vuex的消息", state.sendMsgArr[state.sendMsgArr.length-1].targetId,state.targetId)
            //如果targetId和接收的targetId是一样的 那就说明接收信息的是正在聊天的 清除正在聊天的未读数
            if (state.sendMsgArr[state.sendMsgArr.length-1].targetId == state.targetId) {
                // 清除未读数
                let conversation = state.RongIMClient.Conversation.get({
                    targetId: state.targetId,
                    type: RongIMLib.CONVERSATION_TYPE.PRIVATE,
                });
                conversation.read().then(() => {
                    // console.log("清除指定会话未读数成功");
                    // this.$store.dispatch("changeMsgNum",this)
                    // 发送同步消息
                    conversation
                        .send({
                            messageType: "RC:SRSMsg",
                            content: {
                                //从消息里获取服务器端时间，以最近一条已读 message 为准
                                // lastMessageSendTime: message.sentTime,
                            },
                        })
                        .then((message) => {
                            // console.log("发送同步消息成功", message);
                        });
                });
            }
        },
        //设置正在聊天的人员 设置targetId
        setTargetId(state, newVal) {
            // console.log("targetId===",newVal)
            state.targetId = newVal
        },
        //设置消息列表
        updateMessageList(state,newVal){
            state.messageList = newVal;
        },
        //设置更新消息列表的状态
        updateMsgList(state,newVal){
            state.msgUpdateStatus++
        }

    },
    actions: {
       //获取消息列表 要动态的去获取
       getMessageList(state,newVal) {
        newVal.$store.state.rongyun.RongIMClient.Conversation.getList({
          count: 30,
          startTime: 0,
          order: 0,
        }).then((conversationList) => {
          // console.log("获取会话列表成功", conversationList);
        //   state.messageList = conversationList;
        conversationList.forEach((item) => {
            newVal.$api.userMsg
              .userDetail(item.targetId)
              .then((res) => {
                // console.log(res);
                if (res.result) {
                    newVal.$set(item, "infoData", res.result);
                //   this.$set(
                //     item,
                //     "endTime",
                //     this.formattingTime(item.latestMessage.receivedTime)
                //   );
                }
                //获取消息列表当前的未读数
                let conversation = newVal.RongIMClient.Conversation.get({
                  targetId: item.targetId,
                  type: RongIMLib.CONVERSATION_TYPE.PRIVATE,
                });
  
                conversation.getUnreadCount().then((count) => {
                  // console.log("获取指定会话未读数成功", count);
                  newVal.$set(item, "badge", count);
                });
              })
              .catch((err) => {
                newVal.$message.error(err.message);
              });
          });
          state.commit('updateMessageList',conversationList)
        }).catch((err) => {
            newVal.$message.error(err.message);
          });
      },
    },
    getters: {

    }
}