import Vue from 'vue';
import {
  Dialog,
  Button,
  MessageBox,
  Message,
  Table,
  TableColumn,
  Pagination,
  Steps,
  Step,
  Upload,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Form,
  FormItem,
  Input,
  Collapse,
  CollapseItem,
  Icon,
  Loading,
  Select,
  Option,
  Radio,
  RadioGroup,
  RadioButton,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'element-ui';


Vue.use(Dialog)
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Upload)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Icon)
Vue.use(Select)
Vue.use(Option)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$MessageBox = MessageBox
Vue.prototype.$confirm = MessageBox.confirm