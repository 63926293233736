import index from './apiModel/index' //首页模块接口
import article from './apiModel/article' //文章模块接口
import upload from './apiModel/upload' //上传图片模块接口
import userMsg from './apiModel/userMsg' //用户信息模块接口
import addressList from './apiModel/addressList' //通讯录模块接口
import group from './apiModel/group' //群组相关模块接口
import other from './apiModel/other' //其他模块接口
import filmsFilmsInfo from './apiModel/filmsFilmsInfo' //上传视频模块接口
import member from './apiModel/member' //上传视频模块接口
import email from './apiModel/email' //邮箱模块
export default {
    index,
    article,
    upload,
    userMsg,
    addressList,
    other,
    group,
    filmsFilmsInfo,
    member,
    email
}