// 文章的接口  发蜘客 
import axios from '../axios'
//示例  请求json 就params   body就data:params fromdata就设置请求头
const article = {
    // 文章列表  发蜘客  已发稿件
    articleList(params) {
        return axios({
            url: '/jeecg-circle/circle/articleInfo/articleList',
            method: "get",
            params,
        })
    },

    // 编辑发布文章  发蜘客  发布 
    release(params) {
        return axios({
            url: '/jeecg-circle/circle/articleInfo/release',
            method: "post",
            data: params,
        })
    }, 
    //删除文章  发蜘客   
    deleteArticle(data) {
        return axios({
            url: '/jeecg-circle/circle/articleInfo/addTrashCan',
            method: "post",
            data
        })
    },
    // 垃圾箱列表
    rubbishList(params) {
        return axios({
            url: '/jeecg-circle/circle/articleInfo/getTrashCanList',
            method: "get",
            params,
        })
    },
    //清空垃圾箱
    clearRubbish(id) {
        return axios({
            url: '/jeecg-circle/circle/articleInfo/delTrashCan',
            method: "delete",
        })
    },
    //垃圾箱还原
    rubbishReturn(params) {
        return axios({
            url: '/jeecg-circle/circle/articleInfo/reductionTrashCan',
            method: "post",
            params
        })
    },
     //垃圾箱的删除 调取的是删除文章
     completelyDelete(id) {
        return axios({
            url: '/jeecg-circle/circle/articleInfo/articleDelete/' + id,
            method: "delete",
        })
    },
    //文章详情  
    articleDetail(id, params) {
        return axios.get(`/jeecg-circle/circle/articleInfo/articleDetails/${id}`);
    },
    //编辑蜘客文章
    editArticle(id, params) {
        return axios.post(`/jeecg-circle/circle/articleInfo/editArticle/${id}`, params);
    },
    // 其他接口…………
}
export default article