// 其他的接口
import axios from '../axios'

const member = {
  //根据用户查询用户信息
  memberInfoById(id) {
    return axios({
      url: '/jeecg-member/member/memberInfo/' + id,
      method: "get",

    })
  },
  //签到 群组
  signIn(id) {
    return axios({
      url: '/jeecg-member/group/groupGroupInfo/signIn/' + id,
      method: "get",

    })
  },
  //修改备注和设置(通过好友验证后)
  updateRemarksAndSettings(data, id) {
    return axios({
      url: '/jeecg-member/friend/friendMemberInfo/updateRemarksAndSettings/' + id,
      method: "put",
      data
    })
  },
  //查询用户配置详情
  currentUserConfigDetails(data) {
    return axios({
      url: '/jeecg-member/member/memberMineConfig/mineConfigDetails',
      method: 'GET',
      data
    })
  }
  // 其他接口…………
}
export default member