// 根路径
let baseURL = ''
switch (process.env.NODE_ENV) {
	case "development":
		// baseURL = 'https://www.baidu.com';
		// baseURL = 'http://47.96.129.164:9999/'; //地址
		console.log("地址", 1)
		baseURL = '/api';
		break;
	case 'debug':
		baseURL = 'https://www.ceshi.com';
		console.log("地址", 2)
		break;
	case 'production':
		// baseURL = 'https://www.production.com';
		// baseURL = 'http://47.96.129.164:9999/';
		console.log("地址", 3)
		// baseURL = 'http://pc.test.zhizhumaimai.com/api/';
		//  baseURL = 'https://pc.zhizhumaimai.com/api/';
		//  baseURL = 'https://api.zzxxweb.com/api/';
		baseURL = 'https://pc.zzxxweb.com/api/';
		break;
	default:
		baseURL = ''
		break;
}
export default baseURL