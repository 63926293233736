// 邮箱的接口  发蜘客 
import axios from '../axios'
//示例  请求json 就params   body就data:params fromdata就设置请求头
const email = {
    // 邮件列表 通过邮件类型和邮件拥有者分页查询
    emailList(params) {
        return axios({
            url: '/jeecg-member/member/mail/getMailByType',
            method: "get",
            params,
        })
    },
    // 邮件发送
    sendEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/send',
            method: "post",
            data: params,
        })
    },
    // 邮件保存
    saveEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/save',
            method: "post",
            data:params,
        })
    },
    // 邮件批量删除
    batchDelEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/delete/batch',
            method: "delete",
            params,
        })
    },
    // 邮件批量还原
    batchReturnEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/update/return',
            method: "put",
            params,
        })
    },
    // 邮件批量更新
    batchUpdateEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/updateByIds',
            method: "put",
            params,
        })
    },
    // 根据ID更新邮件
    updateEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/updateById',
            method: "put",
            data: params,
        })
    },
    // 批量更新邮件已读状态
    updateSeeEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/update/see',
            method: "put",
            data: params,
        })
    },
    // 根据邮件拥有者统计各个邮件类型数量
    emailStaticsList(params) {
        return axios({
            url: '/jeecg-member/member/mail/statics',
            method: "get",
            params,
        })
    },
    // 邮件批量彻底删除
    batchDeleteEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/delete/batch',
            method: "delete",
            params,
        })
    },
    // 插入附件
    insertAppendix(params) {
        return axios({
            url: '/jeecg-member/member/mail/appendix/insert',
            method: "post",
            data: params,
        })
    },
    // 根据id删除附件
    delAppendix(id) {
        return axios({
            url: '/jeecg-member/member/mail/appendix/' + id,
            method: "delete",
        })
    },
    // 邮件按类型批量彻底删除 清空
    clearEmail(params) {
        return axios({
            url: '/jeecg-member/member/mail/delete/type',
            method: "delete",
            params,
        })
    },
    //邮件ID查询
    emailDetail(params) {
        return axios({
            url: '/jeecg-member/member/mail/getById',
            method: "get",
            params,
        })
    },
    // 其他接口…………
}
export default email