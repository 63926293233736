// 群组相关接口 秘书台
import axios from '../axios'
//示例  请求json 就params   body就data:params fromdata就设置请求头
const group = {
    // 获取列表 类型 type 0默认群聊 1会议 2活动 3婚礼4宴席5殡礼6庆典7生日会	
    groupGroupInfoList(type) {
        return axios({
            url: '/jeecg-member/group/groupGroupInfo/list/'+ type,
            method: "get",
            // params,
        })
    },
      // 群组用户列表(获取更多成员)
      queryMemberListById(id) {
        return axios({
            url: '/jeecg-member/group/groupGroupInfo/queryMemberListById/' + id,
            method: "get",
        })
    },
    //清除红点
    clearRedDots(id) {
        return axios({
            url: '/jeecg-member/group/groupGroupInfo/clearRedDots/' + id,
            method: "put",
        })
    },
    // 其他接口…………
}
export default group