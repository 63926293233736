import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './element/element'
import 'element-ui/lib/theme-chalk/index.css';
import websoketUrl from './api/websoketUrl'
import VueAMap from 'vue-amap';
import {ryError} from './api/rongYunErro.js'
import base64toFile from "@/api/base64ChangeFile";
// 图片预览插件组件引入
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
})
Vue.use(VueAMap);
Vue.prototype.$ryError = ryError
Vue.prototype.$base64toFile = base64toFile
// 初始化vue-amap
VueAMap.initAMapApiLoader({
    // 高德的key
    key: 'ae630ceffeaebd8ef81d457198bc8764',
    // 插件集合
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
    // 高德 sdk 版本，默认为 1.4.4
    v: '1.4.4',
    uiVersion: '1.0.11' // 版本号
});
Vue.prototype.$websoketUrl = websoketUrl
Vue.use(ElementUI);
// 引入接口
import api from './api/api'
// this.$api.index.接口名
Vue.prototype.$api = api
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
