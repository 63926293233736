export default {
    
    state: {
        token: window.localStorage.getItem('token') || '',
        helloWord: '你好世界111112'
    },
    mutations: {
        //更新token 因为有时候token 通过 localStorage是不同步的
        upDateToken(state,value){
           state.token=value
        }
    },
    actions: {

    },
    getters: {

    }
}