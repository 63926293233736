// 用户的接口
import axios from '../axios'

const userMsg = {
	// 用户详情接口    获取用户详情(含是否好友判断)
	userDetail(id) {
		return axios.get(`/jeecg-member/member/memberInfo/getMemberByBasicInfoDetails/${id}`);
	},
	//获取艺术家空间状态
	memberArtistStatus(params) {
		return axios({
			url: '/jeecg-member/member/memberArtistInfo/memberArtistStatus',
			method: "get",
			params,

		})
	},
	//是否开通蜘客空间的接口
	isOpenBlog(params) {
		return axios({
			url: '/jeecg-circle/circle/memberSpiderBlog/isOpenBlog',
			method: "get",
			params,

		})
	},
	/**
	 * 用户音乐家状态
	 * @param {Object} params
	 */
	memberMusicStatus(params) {
		return axios({
			url: '/jeecg-member/applyMusician/getApplyInfo',
			method: 'GET',
			params
		})
	}
	// 其他接口…………
}
export default userMsg