import {
	Message
} from 'element-ui'
//融云状态码报错
export function ryError(code) {
	switch (code) {
		case -1:
			Message.error('请求超时,请检查网络')
			break;
		case -2:
			Message.error('SDK内部错误')
			break;
		case -3:
			Message.error('开发者参数传入错误')
			break;
		case 405:
			Message.error('已被对方加入黑名单')
			break;
		case 20604:
			Message.error('发送频率过快')
			break;
		case 20605:
			Message.error('信令被封禁，详细请联系商务，电话：13161856839')
			break;
		case 20607:
			Message.error('调用超过频率限制，请稍后再试。')
			break;
		case 22201:
			Message.error('消息扩展/修改，但是原始消息不存在')
			break;
		case 22202:
			Message.error('消息扩展/修改，但是原始消息不支持扩展。')
			break;
		case 22203:
			Message.error('消息扩展/修改，扩展内容格式错误。')
			break;
		case 22204:
			Message.error('无权限操作')
			break;
		case 22406:
			Message.error('不在群组中')
			break;
		case 22408:
			Message.error('在群组中禁言')
			break;
		case 23406:
			Message.error('不在聊天室中')
			break;
		case 23408:
			Message.error('已在聊天室中被禁言')
			break;
		case 23409:
			Message.error('已被踢出并禁止加入聊天室')
			break;
		case 23410:
			Message.error('聊天室不存在')
			break;
		case 23411:
			Message.error('聊天室成员超限')
			break;
		case 23412:
			Message.error('聊天室参数无效')
			break;
		case 23414:
			Message.error('聊天室云存储业务未开通')
			break;
		case 23428:
			Message.error('聊天室批量设置kv不成功')
			break;
		case 23429:
			Message.error('聊天室设置批量kv，一次不能超过10个')
			break;
		case 24401:
			Message.error('超级群功能没有开通')
			break;
		case 24402:
			Message.error('超级群服务异常')
			break;
		case 24403:
			Message.error('超级群参数错误')
			break;
		case 24404:
			Message.error('超级群未知异常')
			break;
		case 24406:
			Message.error('当前用户不在超级群中')
			break;
		case 24408:
			Message.error('当前用户在超级群中已被禁言。')
			break;
		case 24410:
			Message.error('超级群不存在')
			break;
		case 24411:
			Message.error('超级群成员超限制')
			break;
		case 24412:
			Message.error('用户加入超级群数量超限。')
			break;
		case 24413:
			Message.error('创建超级群频道，频道数超限')
			break;
		case 24414:
			Message.error('超级群频道ID不存在')
			break;
		case 25101:
			Message.error('撤回消息失败')
			break;
		case 25102:
			Message.error('未开通单群聊历史消息云存储')
			break;
		case 25103:
			Message.error('清除历史消息时，传递的时间戳大于当前系统时间')
			break;
		case 25105:
			Message.error('清除历史消息时遇到内部异常')
			break;
		case 26001:
			Message.error('push 设置参数无效')
			break;
		case 20605:
			Message.error('操作被禁止')
			break;
		case 20606:
			Message.error('不支持的操作')
			break;
		case 21501:
			Message.error('发送的消息中包含敏感词')
			break;
		case 21502:
			Message.error('消息中敏感词已经被替换')
			break;
		case 30001:
			Message.error('用户未连接成功, 需连接成功后再执行')
			break;
		case 30007:
			Message.error('导航请求失败，请检查网络')
			break;
		case 30010:
			Message.error('CMP 嗅探失败，请检查网络')
			break;
		case 33003:
			Message.error('消息扩展参数错误')
			break;
		case 33007:
			Message.error('未开通单群聊历史消息云存储')
			break;
		case 33007:
			Message.error('未开通单群聊历史消息云存储')
			break;
		case 34008:
			Message.error('消息未设置可扩展参数(原消息 canIncludeExpansion 为 false)')
			break;
		case 34009:
			Message.error('发送扩展消息失败')
			break;
		case 34022:
			Message.error('该接口不支持超级群会话')
			break;
		case 34023:
			Message.error('超级群功能未开通')
			break;
		case 34024:
			Message.error('超级群频道不存在。')
			break;
		case 34010:
			Message.error('消息扩展 key 或 value 超出限制')
			break;
		case 35011:
			Message.error('接口暂不可用。IM 在初次连接成功后，SDK 会立即同步消息、标记上线、并获取全量超级群会话列表。在会话列表同步完成前，所有超级群相关功能接口不可用')
			break;
		case 35014:
			Message.error('该功能不支持超级群')
			break;
		case 35014:
			Message.error('该功能不支持超级群')
			break;
	}
}