// 通讯录的接口  用户好友相关
import axios from '../axios'
//示例  请求json 就params   body就data:params fromdata就设置请求头
const addressList = {
    // 获取通讯录列表
    memberList(params) {
        return axios({
            url: '/jeecg-member/friend/friendMemberInfo/memberList',
            method: "get",
            params,
        })
    },

    // 搜索通讯录  根据昵称 手机号 蜘信号查询 
    getMemberByBasicInfo(params) {
        return axios({
            url: '/jeecg-member/member/memberInfo/getMemberByBasicInfo',
            method: "get",
            params,

        })
    },
    // 其他接口…………
}
export default addressList