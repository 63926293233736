import api from '@/api/api' //引入接口
export default {
	state: {
		uploadMemeNum: 0, //上传表情包列表的数量
		uploadMusicNum: 0, //上传音乐列表的数量
		uploadZhiKNum: 0, //发蜘客的列表数量
		upDateMessageNum: 0, //私信的列表数量
		upDateGroupNum: 0, //群组(秘书台)消息未读数量
		updateGroupStatus: true, //刷新群组(秘书台)列表 
	},
	//更新数量
	mutations: {
		upDateNum(state, status) {
			if (status.path == "上传表情") {
				state.uploadMemeNum = status.num
			}
			if (status.path == "上传音乐") {
				state.uploadMusicNum = status.num
			}
			if (status.path == "发蜘客") {
				state.uploadZhiKNum = status.num
			}
			if (status.path == "私信") {
				state.upDateMessageNum = status.num
			}
		},
		//改变秘书台未读数 一个群组有未读 只计为1
		updateGroumNum(state, newVal) {
			state.upDateGroupNum = newVal
		},
		//刷新群组列表 用一个布尔值来判断 比较容易用watch监听
		changeGroupList(state, newVal) {
			state.updateGroupStatus = !state.updateGroupStatus
		},
	},
	actions: {
		//改变数量 上传表情 上传音乐 发蜘客
		changeNum(state, status) {
			//判断上传表情
			if (status == "上传表情") {
				return new Promise((resolve, reject) => {
					api.other.queryUploadPageList().then(res => {
						state.commit('upDateNum', {
							path: status,
							num: res.result.total
						})
						resolve(res.result.total)
					})
				})
			}
			//判断上传音乐
			if (status == "上传音乐") {
				return new Promise((resolve, reject) => {
					api.upload.getUploadMusicList().then(res => {
						state.commit('upDateNum', {
							path: status,
							num: res.result.total
						})
						resolve(res.result.total)
					})
				})
			}
			//判断发蜘客
			if (status == "发蜘客") {
				return new Promise((resolve, reject) => {
					let num = 0
					//草稿列表
					api.article.articleList({
						status: 0
					}).then(res => {
						num += res.result.total
						//已发列表
						api.article.articleList({
							status: 1
						}).then(resI => {
							num += resI.result.total
							//垃圾箱列表
							api.article.rubbishList().then(resII => {
								num += resII.result.total
								state.commit('upDateNum', {
									path: status,
									num
								})
								resolve(num)
							})
						})
					})
				})
			}
		},
		//监听私信消息 融云私信的消息的数量不准确
		changeMSg(state, status) {
			//判断私信
			if (status.path == "私信") {
				state.commit('upDateNum', {
					path: status.path,
					num: status.num
				})
			}
		},
		//改变融云的私信数量 因为这个需要把this传过来 所以和别的分开
		changeMsgNum(state, newVal) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					// 是否包含免打扰
					const isIncludeNotNotification = false
					newVal.RongIMClient.Tag.get('0').then(tag => {
						tag.getUnreadCount(isIncludeNotNotification).then(result => {
							// 获取未读消息数
							if (!result.code) {
								//console.log('标签会话未读数', result);
								let obj = {}
								obj.path = "私信"
								obj.num = result
								state.commit('upDateNum', obj)
								resolve(result)
							}
						});
					});
				}, 100)
			})
		},
		//改变融云的私信数量 因为这个需要把this传过来 所以和别的分开
		changeGroupMsgNum(state, newVal) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					let conversationTypes = [RongIMLib.CONVERSATION_TYPE.GROUP];
					newVal.RongIMClient.Conversation.getTotalUnreadCount(
						false,
						conversationTypes
					).then((totalUnreadCount) => {
						console.log("======", totalUnreadCount)
						let obj = {}
						obj.path = "群组"
						obj.num = totalUnreadCount
						state.commit('upDateNum', obj)
						resolve(totalUnreadCount)
					});
				}, 100)
			})
		},
		//改变秘书台群组的消息角标数量 要传一个this过来
		actionChangeGroup(state, newVal) {
			//  类型 0默认群聊 1会议 2活动 3婚礼4宴席5殡礼6庆典7生日会
			let arr = [1, 2, 3, 4, 5, 6, 7]
			let countAll = 0
			arr.forEach(item => {
				newVal.$api.group.groupGroupInfoList(item).then((res) => {
					//设置子列表
					res.result.forEach((p) => {
						//获取消息列表当前的未读数
						let conversation = newVal.RongIMClient.Conversation.get({
							targetId: p.id,
							type: RongIMLib.CONVERSATION_TYPE.GROUP,
						});
						conversation.getUnreadCount().then((count) => {
							// console.log("获取指定会话未读数成功", count);
							if (count > 0) {
								countAll += 1
							}
						});
					});
				});
			})
			//延时之后再赋值  要等每个请求
			setTimeout(() => {
				//   console.log("vuex中的count",countAll)
				state.commit('updateGroumNum', countAll)
			}, 1300)
		}
	},
	getters: {}
}