// 群组相关接口 秘书台
import axios from '../axios'
//示例  请求json 就params   body就data:params fromdata就设置请求头
const filmsFilmsInfo = {
  //获取蜘影台列表展示 
  queryListPage(params) {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/queryListPage',
      method: "get",
      params,
    })
  },
  //用户已发列表
  getMemberFilmList(params) {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/getMemberFilmList',
      method: "get",
      params,
    })
  },
  //加入垃圾箱
  delete(params) {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/addTrashCan',
      method: "post",
      data: params,
    })
  },
  //垃圾箱列表
  getTrashCanList(params) {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/getTrashCanList',
      method: "get",
      params,
    })
  },
  //垃圾箱还原
  reductionTrashCan(params) {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/reductionTrashCan',
      method: "post",
      params,
    })
  },
  //清空垃圾箱
  delTrashCan() {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/delTrashCan',
      method: "delete",
    })
  },
  //发布 发布蜘影视频
  addInfo(params) {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/addInfo',
      method: "post",
      data: params,
    })
  },
  //新闻/蜘影剧详情
  getInfo(id) {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/getInfo/' + id,
      method: "get",
    })
  },
  //编辑蜘影
  editFilm(params) {
    return axios({
      url: '/jeecg-circle/circle/filmsFilmsInfo/editFilm',
      method: "post",
      data: params,
    })
  },
  //根据key获取字典数组
  dictList(key) {
    return axios({
      url: '/jeecg-circle/circle/memberSpiderBlog/dict/key/' + key,
      method: 'GET'
    })
  },
  //获取当前用户开设蜘识课堂记录
  openClassroomRecord(data) {
    return axios({
      url: '/jeecg-circle/films/filmsKnowledgeClass',
      method: 'GET',
      data
    })
  }
  // 其他接口…………
}
export default filmsFilmsInfo